import { render, staticRenderFns } from "./mini-reset-password.vue?vue&type=template&id=73a89f9f&scoped=true&"
import script from "./mini-reset-password.ts?vue&type=script&lang=ts&"
export * from "./mini-reset-password.ts?vue&type=script&lang=ts&"
import style0 from "./mini-reset-password.scoped.scss?vue&type=style&index=0&id=73a89f9f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a89f9f",
  null
  
)

export default component.exports