import { encryptAes } from '@/utils/cryption';
import { UserModule } from '@/store/modules/user';
import { passwordReg } from '@/utils/validate';
import { Form } from 'element-ui/types/element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'mini-reset-password'
})
export default class MiniResetPassword extends Vue {
  public resetPasswordForm = {
    password: '',
    checkPassword: ''
  };
  public loginRules = {
    password: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.passwordEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!passwordReg(value)) {
            callback(new Error(this.$t('resetPassword.passwordError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      }
    ],
    checkPassword: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.checkPasswordEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!passwordReg(value)) {
            callback(new Error(this.$t('resetPassword.passwordError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (value !== this.resetPasswordForm.password) {
            callback(this.$t('resetPassword.passwordNotEqual'));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public loading = false;

  public get visible(): boolean {
    return UserModule.resetPassDialog;
  }

  public cancel(): void {
    UserModule.setResetPassDialog(false);
  }

  public handleReset(): void {
    (this.$refs.resetPasswordForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return false;
      }
      this.loading = true;
      UserModule.EditPassword(encryptAes(this.resetPasswordForm.password))
        .then(() => {
          UserModule.setResetPassDialog(false);
          this.$router.push({ path: '/' });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    });
  }

  public dialogClosed(): void {
    (this.$refs.resetPasswordForm as Form).resetFields();
  }
}
